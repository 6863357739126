.admin-editor__body {
    overflow-y: scroll;
}

.admin-fieldset__label{
    font-size: medium;
    margin-left: 2%;
}

.admin-field__label {
    margin-left: 2%;
}
.admin-field__body {
    width: 95%;
    margin-left: 2%;
    margin-right: 1%;
    padding-bottom: 15px;
}

.admin-field-select__label{
    display: inline-block;
    font-family: "Akkurat", sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: inherit;
}

.admin-field.field--select {
    padding-bottom: 15px;
}