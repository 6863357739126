.minner-preview {
    background-color: white;
    color: black;
    overflow: auto;
    block-size: 100%;
    padding: 10px;
}

b {
    font-weight: bold;
}

.top-banner {
    font-size: 30px;
    color: rgb(51, 51, 51);
}

.top-banner-area {
    width: 100%;
    background: #ffdf86;
}

.top-banner-greeting {
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bold;
    padding: 21px 0;
}

.front-page-picture{
    width: 100%;
    height: auto;
}

.welcome-header {
    font-weight: bold;
    font-size: 150%;
    padding: 10px;
    margin-left: 18%;
    margin-right: 18%;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
}

.welcome-text {
    font-size: 15px;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    max-width: 720px;
    letter-spacing: .4px;
    padding: 10px;
    margin-left: 18%;
    margin-right: 18%;
}

input[type="text"],
select, textarea,
.ta-editor {
    height: 47px;
    width: 35%;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    color: rgba(0,0,0,0.75);
    display: block;
    font-size: 1rem;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    margin: 0 0 18px;
    padding: 0.44444rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
    border-radius: 3px;
}

.heading-searchbar {
    height: 30px;
    width: 25%;
    background-color: #FFFFFF;
    border-width: 1px;
    border-color: #cccccc;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    display: block;
    margin: 0 0 18px;
    padding: 0.44444rem;
}

.info-about {
    font-size: 15px;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    max-width: 720px;
    letter-spacing: .4px;
    padding: 10px;
}

.info-help {
    font-size: 15px;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    max-width: 720px;
    letter-spacing: .4px;
    overflow: auto;
    padding: 10px;
}

.info-terms {
    font-size: 15px;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    max-width: 720px;
    letter-spacing: .4px;
    padding: 10px;
}

.info-licenses {
    font-size: 15px;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    max-width: 720px;
    letter-spacing: .4px;
    padding: 10px;
}

.info-contact {
    font-size: 15px;
    font-family: "PT Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif,serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    max-width: 720px;
    letter-spacing: .4px;
    padding: 10px;
}