/*@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";*/

.admin-editor__content {
    background-color: #e7eced;
}

.admin-layout--flex {
    flex-wrap: wrap;
    justify-content: flex-start;

    .admin-widget {
        margin: 1em;
        padding: 1em;
        width: 30em;

        cursor: pointer;

        h1 {
            font-size: 200%;
            font-weight: bold;
            line-height: 100%;
            margin-top: 0;
        }
        
        h1.memory {
            font-size: 120%;
        
        }
    }
}
.memory-preview-thumbnail {
    width: 392px;
}

.admin-editor {
    .admin-editor__body,
    .admin-editor__content,
    .admin-document__content {
        overflow-y: auto;
    }
}

.admin-widget__header h1 {
    margin-bottom: 20px;
}

.ReactModal__Content {
    h1 {
        font-size: 28px;
        margin-top: 0;
    }
}

section.info-text {
    margin: 24px;
    margin-top: 48px;
    font-size: 125%;
    h1 {
        text-align: center;
        font-weight: bold;
        font-size: 175%;
        // padding: 21px 0;
        margin: 24px 0;
    
    }
    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 150%;
        // padding: 21px 0;
        margin: 0 0 48px 0;
    
    }

}