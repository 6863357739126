.admin-button {
    .admin-button__icon {
        vertical-align: middle;
        padding-bottom: 2px;
    }
}
.admin-field {
    .admin-button {
        margin-top: 10px;
    }
}
.footer {
    margin-top: 15px;
}
.name-list::after {
    content: ", ";
}
.name-list:last-child::after {
    content: none;
}

.admin-button.admin-button--primary.bg-primary {
    background-color: #de6773;
    color: #fff;
}

.nav-status,
.nav-toggle {
    display: none!important;
}

aside.admin__sidebar {
    background-color: #fff;
    border-right: 1px solid #ddd;
}

aside.admin__sidebar nav.nav-action {
    margin-bottom: 0;
}

aside.admin__sidebar button.admin-button {
    margin-left: 12px;
    margin-top: 12px;
}

aside.admin__sidebar > .nav-menu {
    position: relative;
    padding-top: 24px;
    overflow-y: auto;

    li.nav-menu__item {
        font-size: 125%;

        a {
            color: #008FAD;
        }
    }
}
